.add-comment {
    display: flex;
    margin: 20px auto;
    width: 50%;
    max-width: 450px;
    border-radius: 5px;
}
.add-comment input {
    background-color: var(--jet);
    color: var(--gre);
}
.add-comment button {
    margin-left: 5px;
}

.comment {
    flex-direction: column;
    width: 30%;
    max-width: 450px;
    margin: 10px auto 10px auto;
    text-align: left;
}
.comment-header {
    display: inline-flex;
    font-size: 1.2em;
    align-items: center;
    padding: 5px 5px 0 5px;
}
.comment-header a {
    margin: 0 10px;
    font-weight: bold;
    color: var(--red);
}

.comment-data {
    float: right;
}

.comment-body {
    text-align: left;
    background-color: var(--jet);
    border-radius: 5px;
    padding: 0 0 5px 0;
    overflow-wrap: anywhere;
}
.comment-body p {
    margin: 0;
    padding: 10px 0 0 10px;
}
.comment-body a {
    color: var(--gre);
}
.comment-body div {
    text-align: end;
    padding: 0 5px 5px 0;
}

.comment-data {
    float: right;
    padding: 5px 10px 0 20px;
}
.comment-like-button {
    margin: 0 10px 10px 10px;
    padding: 0;
    color: var(--gre);
    border: none;
    background-color: transparent;
    fill: transparent;
    height: 25px;
    width: 25px;
}
.comment-like-button-filled {
    margin: 0 10px 10px 10px;
    padding: 0;
    color: var(--gre);
    border: none;
    background-color: transparent;
    fill: var(--blu);
    height: 25px;
    width: 25px;
}

/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
    .comment {
        flex-direction: column;
        width: 80%;
        max-width: 450px;
        margin: 10px auto 10px auto;
        text-align: left;
        z-index: 9900;
    }
    .add-comment {
        display: flex;
        margin: 20px auto;
        width: 90%;
        max-width: 450px;
        border-radius: 5px;
    }
    .add-comment input {
        background-color: var(--jet);
        color: var(--gre);
    }
    .add-comment button {
        margin-left: 5px;
    }
}