.chatlist {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 15%;
  margin: auto 20px auto auto;
  background-color: var(--jet);
  color: var(--gre);
  font-weight: bold;
  border-radius: 5px;
  overflow-y: scroll;
}
.chatlist ul {
    text-align: left;
    list-style: none;
    padding: 0.5em 0 0 0;
}
.chatlist li {
  margin-left: 0.5em;
  padding: 10px 10px;
  text-wrap: nowrap;
}

.selected-chat {
  background-color: var(--blu);
  border-radius: 5px;
}
.selected-chat svg {
    background-color: var(--blu);
    fill: var(--blu);
}
  
.chatbox {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: var(--bak);
  border-radius: 5px;
  margin: 25px auto 0 0;
}
.chatheader {
  flex: 0 1 auto;
  margin: 0;
  background-color: var(--jet);
  border-radius: 5px;
  margin-bottom: 5px;
}
.chatheader a {
    color: var(--red);
}
.messagebox-wrapper{
    height: 500px;
    display: flex;
    flex-direction: column-reverse;
    flex: 1 1 auto;
    overflow: auto;
    background-color: var(--jet);
    border-radius: 5px;
    margin-bottom: 5px;
}
.message {
  margin: 10px 20px;
  padding: 5px;
  border-radius: 5px;
  color: var(--jet);
}
.message h5 {
  margin: 0 5px;
}
.message p {
  margin: 0;
  padding: 8px;
  border-radius: 5px;
  display: inline-block;
  overflow-wrap: anywhere;
  max-width: 70%;
}
.message button {
    color: var(--gre);
    border: none;
    background-color: transparent;
    fill: var(--jet);
    height: 25px;
    width: 25px;
}

.incoming-message {
  text-align: left;
}
.incoming-message h5 {
  color: var(--red);
}
.incoming-message p {
  background-color: var(--red);
}
.outgoing-message {
  text-align: right;
}
.outgoing-message h5 {
  color: var(--sil);
}
.outgoing-message p {
  background-color: var(--sil);
}

.new-message svg {
    fill: var(--red);
}

/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
    .chatbox {
        display: block;
        width: 90%;
        margin: 25px;
    }
    .chats-toggle{
        position: absolute;
        left: 0;
        margin-left: 15px;
        padding: 5px;
    }
    .chats-toggle svg{
        height: 35px;
        width: 35px;
        margin-bottom: 4px;
    }
    .chatlist {
        margin: 20px;
        margin-top: 25px;
        position: fixed;
        display: block;
        float: left;
        left: 0;
        top: 120px;
        width: 90%;
        text-align: left;
        z-index: 9990;
        border: 2px solid transparent;
    }
    .chatlist ul {
        width: 100%;
        height: 100%;
        background-color: var(--jet);
        margin: 0;
        padding: 5px;
        border-radius: 5px;
        list-style: none;
        text-align: left;
        max-height: 400px;
        overflow-y: scroll;
        position: absolute;
        z-index: 9990;
        left: 0;
    }
    .chatlist li {
      padding: 10px;
      margin: 5px;
      display: block;
      text-wrap: nowrap;
      z-index: 9990;
    }
}