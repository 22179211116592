.search-header {
    display: block;
    border-radius: 15px;
}
.search-header button  {
    border-radius: 0;
    font-size: 1.2em;
}

.search-bar {
    margin: 16px auto 0 auto;
    width: 40%;
}

.off-toggle {
    background-color: var(--jet);
}
.on-toggle {
    background-color: var(--blu);
}

.search-results {
    list-style: none;
    padding: 0;
    width: 30%;
    margin: auto;
    color: var(--gre);
    font-weight: bold;
}
.search-results li {
    margin: 15px;
    padding: 10px;
    background-color: var(--jet);
    border-radius: 5px;
    list-style: none;
}
.search-results a {
    color: var(--red);
}

/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
    .search-bar {
        margin: 16px auto 0 auto;
        width: 90%;
    }
    .search-results {
        width: 85%;
    }
}