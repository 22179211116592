:root {
    --blu: #B7245C;/* #6464FF; */
    --gre: #FBFBFB;/* #64FF64; */
    --red: #009FFD;/* #FF6464; */
    --jet: #323232;
    --bak: #191919/* #191919 */;
    --sil: #C8C8C8;   /*#C8C8C8;*/
}

#root {
  height: 100%;
  width: 100%;
}
body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gre) ;
  background-color: var(--bak);
  height: 100%;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
background-color: var(--jet);
outline: 1px solid --sil;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
