

.on-post-list {
    width: 50%;
    max-width: 550px;
}
.post {
    flex-direction: column;
    margin: 15px auto 15px auto;
    border-radius: 5px;
    padding: 10px 0 10px 0;
    text-align: left;
    background-color: var(--jet);
}
.post-header {
    display: inline-flex;
    font-size: 1.2em;
    align-items: center;
    padding: 15px 0 5px 20px;
}
.post-header a {
    margin: 0 10px;
    font-weight: bold;
    color: var(--red);
}
.add-post-header{
    margin: 0 10px;
    font-weight: bold;
    color: var(--red);
}

.post-data {
    float: right;
    padding: 15px 20px 0 0;
}

.post-body {
    text-align: left;
    padding: 0;
    overflow-wrap: anywhere;
}
.post-body p {
    margin: 0;
    padding: 8px 30px 8px 30px;
}
.post-body a {
    color: var(--gre);
}
.post button {
    margin: 0 5px 0 10px;
    padding: 0;
}
.like-button {
    color: var(--gre);
    border: none;
    background-color: var(--jet);
    fill: var(--jet);
    height: 25px;
    width: 25px;
}
.like-button-filled {
    color: var(--gre);
    border: none;
    background-color: var(--jet);
    fill: var(--blu);
    height: 25px;
    width: 25px;
}
.comment-button {
    color: var(--gre);
    border: none;
    background-color: var(--jet);
    fill: var(--jet);
    height: 25px;
    width: 25px;
}
.create-post-link {
    position: fixed;
    display: block;
    background-color: transparent;
    font-size: 1em;
    text-decoration: none;
    margin: 25px;
    color: var(--red);
}
.create-post-link svg {
    display: block;
    height: 50px;
    width: 50px;
    stroke: var(--gre);
    fill: var(--blu);
}

/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
    .post {
        flex-direction: column;
        margin: 15px auto 15px auto;
        border-radius: 5px;
        padding: 10px 0 10px 0;
        text-align: left;
        background-color: var(--jet);
        z-index: 9979;
    }
	.on-post-list {
        width: 90%;
        max-width: 550px;
    }
    .create-post-link {
        position: fixed;
        bottom: 0;
        display: block;
        background-color: transparent;
        font-size: 1em;
        text-decoration: none;
        margin: 25px;
        color: var(--red);
        z-index: 9989;
    }
}