.profile-card {
    margin: 25px auto 0 auto;
    background-color: var(--jet);
    color: var(--gre);
    text-align: left;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    max-width: 570px;
}

.profile-settings {
    float: right;
    text-wrap: nowrap;
    margin: 0 0 15px 0;
    overflow: visible; 
    align-items: center;
    padding: 15px 20px 0 20px;
}
.profile-header {
    display: inline-flex;
    margin: 0 0 15px 0;
    font-size: 1.2em;
    align-items: center;
    padding: 15px 20px 0 20px;
    color: var(--red);
}

.profile-header input {
    border: 1px solid var(--sil);
}
.profile-body input {
    border: 1px solid var(--sil);
}

.profile-header p {
    margin: 0 10px 0 10px;
}
.profile-header h2 {
    margin: 0 10px 0 0;
}

.profile-body {
    text-align: left;
    padding: 5px 20px 10px 20px;
    text-wrap: wrap;
    overflow-wrap: anywhere;
}

.profile-posts-header {
    font-weight: bold;
    padding-bottom: 10px;
    margin: 0;
    width: 100%;
    display: block;
    text-align: center;
    border-bottom: 2px solid var(--gre);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    position: sticky;
    z-index: 1;
    top: 57px;
    overflow: hidden;
    background-color: var(--jet);
}


.tags {
    margin: 15px;
    align-items: center;
}
.tags div {
    display: inline-flex;
    margin: 5px;
    padding: 7px;
    background-color: var(--red);
    color: var(--jet);
    border-radius: 20px;
}
.tags button {
    margin: 0 3px 0 5px;
    padding: 0;
    border: none;
    color: var(--jet);
    background-color: var(--red);
}