:root {
  --blu: #B7245C;/* #6464FF; */
  --gre: #FBFBFB;/* #64FF64; */
  --red: #009FFD;/* #FF6464; */
  --jet: #323232;
  --bak: #191919/* #191919 */;
  --sil: #C8C8C8;   /*#C8C8C8;*/
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

nav {
    position: fixed;
    width: 100%;
    border-bottom: 2px solid var(--gre);
    background-color: var(--jet);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 1000;
}
nav ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding: 10px 0;
    margin: 0;
}
nav ul li {
    display: inline;
    border-radius: 5px;
    border: 2px solid var(--jet);
}
.navlink {
    font-size: 1.5em;
    color: var(--sil);
    text-decoration: none;
    padding: 0 10px;
    display: inline-block;
    height: 35px;
}
.nav-selected {
    border-radius: 5px;
    border: 2px solid var(--red);
}
.navlink svg {
    color: var(--gre);
    border: none;
    background-color: var(--jet);
    fill: var(--jet);
    height: 35px;
    width: 35px;
}
.navlink svg:hover {
    stroke: var(--red);
}
.nav-branding {
    display: inline;
    text-align: left;
    font-size: 1.5em;
    float: left;
    position: absolute;
    left: 10px;
    top: 10px
}
.nav-branding img {
    height: 30px;
    margin: 0 10px 5px 20px;
}

button {
    padding: 10px;
    border: none;
    background-color: var(--blu);
    color: var(--sil);
    font-size: 1em;
    text-decoration: none;
    border-radius: 5px;
}
button:disabled {
    color: var(--jet);
    background-color: var(--sil);
}

.page-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    max-height: 90%;
}

textarea {
    resize: none;
    width: 100%;
    height: 150px;
    background-color: var(--jet);
    border: 1px solid var(--sil);
    border-radius: 5px;
    color: var(--sil);
    outline: none;
}
textarea:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 0.6em;
}

::-webkit-scrollbar-thumb {
    background-color: var(--blu);
    border-radius: 15px;
}


.send-text {
    flex: 0 1 50px;
    display: flex;
    flex-direction: row;
    margin: 0;
}
.send-text button {
    margin-left: 5px;
}

a {
  text-decoration: none;
  color: var(--sil);
}

input {
    width: 100%;
    border-style: none;
    border-radius: 5px;
    background-color: var(--jet);
    color: var(--gre);
}
input:focus {
    outline: none;
    font-size: 1em;
}

.time-stamp {
    font-size: 0.7em;
    color: var(--sil);
    margin: 0;
}

.notifs {
    margin: 25px;
    position: fixed;
    float: right;
    right: 0;
    top: 60px;
    text-align: right;
    z-index: 9999;
    border: 2px solid transparent;
}
.notifs ul {
    box-shadow: 0 0 10px 5px var(--bak);
    background-color: var(--jet);
    margin: 0;
    padding: 5px;
    border-radius: 5px;
    list-style: none;
    text-align: left;
    max-height: 400px;
    overflow-y: scroll;
    position: absolute;
    z-index: 9999;
    right: 0;
}
.notifs li {
  padding: 10px;
  margin: 5px;
  display: block;
  text-wrap: nowrap;
  z-index: 9999;
}
.notifs label {
    font-size: 1.2em;
    color: var(--gre);
    background-color: var(--red);
    padding: 0 5px;
    border-radius: 25px;
}
.new-chats svg {
    stroke: var(--gre);
    fill: var(--red);
}
.new-chats svg:hover {
    fill: var(--gre);
}

.notif-toggle {
  text-align: right;
  padding: 0px;
  margin: 1px;
  background-color: transparent;
  color: var(--red);
  border: none;
}
.notif-toggle svg {
    display: block;
    height: 50px;
    width: 50px;
    stroke: var(--gre);
    fill: var(--blu);
}
.new-notifs svg {
    stroke: var(--gre);
    fill: var(--red);
}

.unread {
    background-color: var(--red);
    border-radius: 5px;
}
.unread a {
    color: var(--jet);
}

.hide {
    display: none;
}

.back-button {
    position: fixed;
    display: block;
    background-color: var(--blu);
    border-radius: 5px;
    color: var(--sil);
    font-size: 1em;
    text-decoration: none;
    margin: 25px;
    padding: 15px;
}

.button-icon {
    fill: var(--blu);
    background-color: transparent;
    color: var(--gre);
    height: 25px;
    width: 25px;
}

.cover {
    background-color: var(--bak);
    padding-top: 20px;
    padding-bottom: 8px;
    position: sticky;
    top: 57.5px;
    width: 60%;
    max-width: 570px;
}
.non-sticky-cover {
    background-color: var(--bak);
    padding-top: 20px;
    padding-bottom: 8px;
    max-width: 570px;
}

.branding {
    position: fixed;
    top:0;
    padding: 10px;
    width: 100%;
    border-bottom: 2px solid var(--gre);
    background-color: var(--jet);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    text-wrap: nowrap;
}
.branding h1 {
    display: inline;
    margin: 0 0 0 25px;
}
.branding h3 {
    display: inline;
    margin: 0 25px 0 50px;
}
.branding img {
    height: 50px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.nav-branding {
    display: inline;
    text-align: left;
    font-size: 1.5em;
}
.nav-branding img {
    height: 30px;
    margin: 0 10px 5px 20px;
}
.logo-color-1 {
    color: var(--sil);
}
.logo-color-2 {
    color: var(--red);
}
.logo-text {
    font-size: 2.5rem;
}

.modal {
    color: var(--gre);
}
.modal-content {
    background-color: var(--jet) !important;
}
.modal-header {
    color: var(--red);
    border: none;
}
.modal-footer {
    border: none;
}

.tablet-wrapper {
    display: none !important;
}
/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
	.desktop-wrapper {
        display: none !important;
    }
    .tablet-wrapper {
        display: block !important;
    }
    .App {
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      font-size: 0.9em;
    }
	.notifs {
        position:unset;
        display: inline;
        margin: 0;
        float:none;
        text-align: right;
        z-index: 9999;
    }
    .notifs ul {
        margin-top: 15px;
        width: 70%;
    }
    .notif-toggle svg {
        display: inline;
        height: 35px;
        width: 35px;
        margin-bottom: 4px;
    }
    .notifs button {
        height: auto;
        text-align: center;
    }
    .notif-toggle {
        display: inline;
        text-align: right;
        padding: 0 10px;
        margin: 0;
        border: none;
    }
    .notifs label {
        font-size: 1em;
    }
    .back-button{
        bottom: 0;
        z-index: 9979;
    }
    .cover {
        width: 90%;
        z-index: 9979;
    }
}