table {
    min-width: 650;
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.accountform {
    text-align: left;
    width: 400px;
    margin: 0 auto;
    padding: 35px;
    background-color: var(--jet);
    color: var(--gre);
    font-size: 1.2em;
    border-radius: 5px;
}

.accountform label {
    width: 100%;
    margin: 1em 0 0 0;
}

.accountform input {
    width: 100%;
    border: 1px solid var(--sil);
    background-color: var(--jet);
    color: var(--sil);
}
.accountform input:focus {
    outline: none;
    font-size: 1em;
}
.accountform button {
    width: 100%;
    margin: 1.5em 0 0 0;
}
.accountform button:disabled {
    color: var(--jet);
    background-color: var(--sil);
}
.accountform select {
    background-color: var(--jet);
    color: var(--sil);
    border: 1px solid var(--sil);
    border-radius: 5px;


}
.errmsg {
    border: 5px inset var(--red);
    padding: 3px;
    background-color: var(--red);
    text-align: center;
    color: var(--jet);
}

.offscreen {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.valid {
    border: 1px solid var(--jet);
}

.invalid {
    border: 1px solid var(--jet);
}

.instructions {
    background-color: var(--jet);
    color: var(--sil);
    margin: 0.5em 0 0 0;
    padding: 10px;
    font-size: 0.8em;
}

.accept-lfg:disabled {
    color: var(--jet);
    background-color: var(--sil);
}

.expand {
    width: auto !important;
    height: auto !important;
    margin-left: 10px !important;
}
.expanded {
    width: 40%;
    max-width: 400px;
    border: 1px solid var(--sil);
    border-radius: 5px;
    background-color: var(--jet);
    color: var(--sil);
    font-size: 0.8em;
    padding: 5px;
    text-align: left;
}

/*media query for non-desktop*/
@media screen and (max-width: 750px)
{
    .accountform {
        width: 90%;
    }
    .expanded {
        width: 80%;
    }
}